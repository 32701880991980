import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"product-action text-center",class:{ 'reduced-width': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"elevation":"0","rounded":"xl"}},[_c(VCardTitle,{staticClass:"text-center d-block black--text action-title"},[_vm._t("title")],2),_c(VCardText,{staticClass:"black--text flex-grow-1 text-body-1"},[_vm._t("description")],2),_c(VCardActions,{staticClass:"justify-center pb-4"},[_c(VBtn,{staticClass:"text-uppercase px-10",attrs:{"href":_vm.link,"rounded":"","color":"primary"}},[_vm._t("button")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }