<template>
    <v-card
        elevation="0"
        rounded="xl"
        class="product-action text-center"
        :class="{ 'reduced-width': $vuetify.breakpoint.mdAndUp }"
    >
        <v-card-title class="text-center d-block black--text action-title">
            <slot name="title"></slot>
        </v-card-title>
        <v-card-text class="black--text flex-grow-1 text-body-1">
            <slot name="description"></slot>
        </v-card-text>
        <v-card-actions class="justify-center pb-4">
            <v-btn
                :href="link"
                rounded
                color="primary"
                class="text-uppercase px-10"
            >
                <slot name="button"></slot>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const ProductActionProps = Vue.extend({
    name: 'ProductAction',
    props: {
        link: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component
export default class ProductAction extends ProductActionProps {}
</script>

<style lang="scss" scoped>
.product-action::v-deep {
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &.reduced-width {
        max-width: 36ch;
    }

    .action-title {
        word-break: keep-all;
        font-size: 1.5rem;
    }
}
</style>
